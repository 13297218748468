import { useUser } from '@auth0/nextjs-auth0/client'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toJS } from 'mobx'
import { useTranslation } from 'next-i18next'
import { myProfileQueryKey } from '../../../../../api/constants'
import { myAccountEndpoint } from '../../../../../api/endpoints'
import { IUserMe, IAccountLocalData } from '../../../../../api/types'
import { accountProfileRouteName } from '../../../../../constants'
import useOfflineMutation from '../../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../../models/useMst'
import { trackEmailUpdated, trackPhoneUpdated } from '../../../../Analytics/trackers'

export const updateProfile = async (email?: string | null, phone_number?: string | null): Promise<IUserMe> => {
  const payload: { email?: string | null; phone_number?: string | null } = {}

  if (email) payload.email = email
  if (phone_number) payload.phone_number = phone_number

  const res = await axios.put<{ data: IUserMe }>(myAccountEndpoint, payload)
  return res.data.data
}

function optimisticUpdateMyProfile(queryClient: QueryClient, data: IAccountLocalData) {
  if (data) {
    const key = myProfileQueryKey(data.user_id);

    // optimistic update
    queryClient.setQueryData<IUserMe>(key, (profile: IUserMe | undefined) => {
      if (profile) {
        const { email, phone_number } = data;
        return {
          ...profile,
          email: email ?? null,
          phone_number: phone_number ?? null
        };
      }
    });

    // invalidate the query to trigger a refetch
    queryClient.invalidateQueries(key);
  }
}

export function useSyncProfile() {
  const { profile } = useMst();
  const queryClient = useQueryClient();
  const { user } = useUser();

  return async () => {
    try {
      const data = toJS(profile.localProfile);

      if (data) {
        const { email, phone_number } = data;

        if (!email && phone_number) {
          await updateProfile(null, phone_number);
        } else if (!phone_number && email) {
          await updateProfile(email, null);
        } else if (email && phone_number) {
          await updateProfile(email, phone_number);
        }

        profile.didUpdateProfile();

        optimisticUpdateMyProfile(queryClient, data);

        if (user) {
          if (phone_number !== undefined) {
            trackPhoneUpdated(user, accountProfileRouteName);
          }
          if (email !== undefined) {
            trackEmailUpdated(user, accountProfileRouteName);
          }
        }

      }
    } catch (e) {
      throw e;
    }
  };
}

export default function useMutateMe() {
  const { messages, profile } = useMst();
  const queryClient = useQueryClient();
  const { t } = useTranslation('account-profile');

  return useOfflineMutation({
    addLocal: (data: IAccountLocalData) => {
      profile.enqueueForUpdate(data);
    },
    removeLocal: () => {
      profile.didUpdateProfile();
    },
    saveRemote: async (data: IAccountLocalData) => {
      const { email, phone_number } = data; // Extract user_id and contact_email from data
      try {
        await updateProfile(email, phone_number); // Call updateProfile with userId and email
        return true; // Indicate successful update
      } catch (error) {
        throw error; // Throw error for handling in onError
      }
    },
    onSuccess: (data: IAccountLocalData, saved) => {
      if (saved) {
        messages.showMessage(t('updatedSuccessfully'), 'success');
        optimisticUpdateMyProfile(queryClient, data);

        // Invalidate and refetch the profile data
        queryClient.invalidateQueries(['me']);
      }
    },
    onError: (error: unknown) => {
      const err = error as AxiosError<any>;

      let errorMessage;

      if (err.response?.data?.errors?.phone_number?.[0]) {
        errorMessage = 'Error: ' + err.response.data.errors.phone_number[0];
      } else if (err.response?.data?.errors?.email?.[0]) {
        errorMessage = 'Error: ' + err.response.data.errors.email[0];
      } else if (err.response?.data?.message) {
        errorMessage = 'Error: ' + err.response.data.message;
      } else {
        errorMessage = t('errorUpdating', { error: err.message });
      }
      
      messages.showMessage(errorMessage, 'error');
    },
  });
}
