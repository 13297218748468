import React from 'react'
import { INavigator } from '../../Navigation/types'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { useTranslation } from 'react-i18next'
import useNavigator from '../../Navigation/useNavigator'
import { Box, Typography } from '@mui/material'

interface IConfirmationPageProps {
  id?: string
  navigator?: INavigator
}

export default function ConfirmationPage({ }: IConfirmationPageProps) {
  const navigator = useNavigator()
  const { t } = useTranslation('update-details')
  const title = t('updateDetails')

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={title}
        />
      }
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} py={8}>
        <Typography variant="h5" component="h2" mb={3}>
          Thank you for updating your details
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          These updates will display as pending until they have been accepted.
        </Typography>
      </Box>
    </AuthLayout>
  )
}