import AuthLayout from "../../AuthLayout"
import CamfedAppBar from "../../CamfedAppBar"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const BTECThankYouPage = () => {
  const { t } = useTranslation('btec')
  const title = t('btecSubmitted')

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={title}
        />
      }
    >
      <Box sx={{ maxWidth: '1024px', mt: 6, mx: 'auto', px: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Thank you for updating your details
          </Typography>
        </Box>
        <Box sx={{ mb: 5 }}>
          <Typography variant="body1" gutterBottom>
            These updates will display as pending until they have been accepted.
          </Typography>
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default BTECThankYouPage