import React from 'react'
import { Typography, TextField, Grid, Box, Link } from '@mui/material'

interface EmailChangeProps {
  currentEmail: string
  newEmail: string
  confirmNewEmail: string
  onNewEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onConfirmNewEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errorMessage: string
}

const EmailChange: React.FC<EmailChangeProps> = ({ currentEmail, newEmail, confirmNewEmail, onNewEmailChange, onConfirmNewEmailChange, errorMessage }) => {
  const shouldDisplayNotice = currentEmail.includes('LMEmail') || currentEmail.includes('lmemail')

  return (
    <Box mb={3}>
      <Typography variant="h6" component="h2" gutterBottom>
        Email Change
      </Typography>
      {shouldDisplayNotice && (
        <Typography variant="body1" color="textSecondary" gutterBottom>
          As we did not have a unique email address for your account, we have setup the following email to enable initial login. Please update below.
        </Typography>
      )}
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Current Email Address"
              value={currentEmail}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography component="p" gutterBottom>
              Add Email
            </Typography>
            <TextField
              type="email"
              label="New Email Address"
              value={newEmail}
              onChange={onNewEmailChange}
              fullWidth
              margin="normal"
              error={!!errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography component="p" gutterBottom>
              Confirm Email
            </Typography>
            <TextField
              type="email"
              label="Confirm New Email Address"
              value={confirmNewEmail}
              onChange={onConfirmNewEmailChange}
              fullWidth
              margin="normal"
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={2} marginTop={'12px'}>
            <Box height={24} sx={{ marginBottom: '5.6px' }} />
            <Typography variant="body1" color="textSecondary">
              Don’t have an email? <Link href="https://support.google.com/mail/answer/56256" color="primary" target="_blank" rel="noopener noreferrer">Click here</Link> to set one up
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default EmailChange