import { useTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Box } from '@mui/material'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import Intro from '../../Auth/AuthFlow/Intro'
import EmailChange from '../../Auth/AuthFlow/EmailChange'
import PhoneNumberChange from '../../Auth/AuthFlow/PhoneNumberChange'
import useMe from '../../Auth/useMe'
import axios from 'axios'
import useMst from '../../../models/useMst'
import useMutateAndRedirect from '../Account/Profile/hooks/useMutateMeConfirmation'
import useConfirmCheck from '../../Auth/useConfirmCheck'
import { updateDetailsConfirmationRoute } from '../../../constants'
import FullScreenSpinner from '../../FullScreenSpinner/FullScreenSpinner'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export default function UpdateDetailsPage() {
  const { router } = useMst()
  const queryClient = useQueryClient()
  const { resetRoutes, pushRouteOrBringToTop } = router
  const { t } = useTranslation('update-details')
  const title = t('updateDetails')
  const nextRouter = useRouter()

  const redirectToConfirmation = useCallback(() => {
    pushRouteOrBringToTop(updateDetailsConfirmationRoute)
  }, [pushRouteOrBringToTop])

  const redirectToHome = useCallback(() => {
    resetRoutes()
  }, [resetRoutes])

  const { data: profile } = useMe()
  const currentEmail = profile?.email || ''
  const currentPhoneNumber = profile?.phone_number || ''
  const country = profile?.country || '' // Retrieve country from profile

  const [newEmail, setNewEmail] = useState('')
  const [confirmNewEmail, setConfirmNewEmail] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState({ countryCode: '', number: '' })
  const [confirmNewPhoneNumber, setConfirmNewPhoneNumber] = useState({ countryCode: '', number: '' })
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [loading, setLoading] = useState(false)

  const { mutateAsync } = useMutateAndRedirect()

  const handleSkip = async () => {
    try {
      setLoading(true)
      await axios.post('/api/account/confirm_check', { action: 'skipped' })
      redirectToHome()
    } catch (error) {
      console.error('Error skipping:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleConfirm = async () => {
    let isValid = true

    if (newEmail && confirmNewEmail && newEmail !== confirmNewEmail) {
      setEmailError('Emails do not match')
      isValid = false
    } else {
      setEmailError('')
    }

    if (newPhoneNumber.number && confirmNewPhoneNumber.number &&
      (newPhoneNumber.number !== confirmNewPhoneNumber.number || newPhoneNumber.countryCode !== confirmNewPhoneNumber.countryCode)) {
      setPhoneError('Phone numbers and country codes do not match')
      isValid = false
    } else {
      setPhoneError('')
    }

    if (!isValid) {
      return
    }

    const shouldUpdateProfile = (newEmail && confirmNewEmail && newEmail === confirmNewEmail) ||
      (newPhoneNumber.number && confirmNewPhoneNumber.number &&
        newPhoneNumber.number === confirmNewPhoneNumber.number &&
        newPhoneNumber.countryCode === confirmNewPhoneNumber.countryCode)

    if (shouldUpdateProfile && profile?.id) {
      try {
        setLoading(true)
        await mutateAsync({
          data: {
            user_id: String(profile.id),
            email: newEmail || '',
            phone_number: newPhoneNumber.number ? `${newPhoneNumber.countryCode}${newPhoneNumber.number}` : ''
          },
          onSuccess: async (data, saved) => {
            if (saved) {
              await axios.post('/api/account/confirm_check', { action: 'confirmed' })
              queryClient.invalidateQueries(['me'])
              redirectToConfirmation()
            }
          }
        })
      } catch (error) {
        console.error('Error confirming:', error)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        await axios.post('/api/account/confirm_check', { action: 'confirmed' })
        queryClient.invalidateQueries(['me'])
        redirectToConfirmation()
      } catch (error) {
        console.error('Error confirming:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const { data, isLoading, isError } = useConfirmCheck()

  useEffect(() => {
    if (!isLoading && !isError && data === 'no_content') {
      redirectToHome()
    }
  }, [data, isLoading, isError, redirectToHome])

  // Effect to refetch data on route change
  useEffect(() => {
    const handleRouteChange = () => {
      queryClient.invalidateQueries(['me'])
      queryClient.invalidateQueries(['confirmCheck'])
    }

    nextRouter.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      nextRouter.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [nextRouter.events, queryClient])

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={title} hideRightButtons />}
    >
      <FullScreenSpinner loading={loading} />
      <Box padding={3}>
        <Intro />
        <EmailChange
          currentEmail={currentEmail}
          newEmail={newEmail}
          confirmNewEmail={confirmNewEmail}
          onNewEmailChange={(e) => setNewEmail(e.target.value)}
          onConfirmNewEmailChange={(e) => setConfirmNewEmail(e.target.value)}
          errorMessage={emailError}
        />
        <PhoneNumberChange
          currentPhoneNumber={currentPhoneNumber}
          newPhoneNumber={newPhoneNumber}
          confirmNewPhoneNumber={confirmNewPhoneNumber}
          onNewPhoneNumberChange={(field, value) => setNewPhoneNumber({ ...newPhoneNumber, [field]: value })}
          onConfirmNewPhoneNumberChange={(field, value) => setConfirmNewPhoneNumber({ ...confirmNewPhoneNumber, [field]: value })}
          errorMessage={phoneError}
          country={country} // Pass country to PhoneNumberChange
        />
        <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
          <Button onClick={handleSkip} color="primary" disabled={loading}>
            Skip for now
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" disabled={loading}>
            Confirm
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  )
}