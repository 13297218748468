import React, { useCallback, useState } from 'react'
import { Box, Typography, Button, Checkbox, FormControlLabel, Link, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next"
import AppBarBackButton from "../../AppBarBackButton"
import AuthLayout from "../../AuthLayout"
import CamfedAppBar from "../../CamfedAppBar"
import { INavigator } from "../../Navigation/types"
import useNavigator from "../../Navigation/useNavigator"
import useMst from '../../../models/useMst'
import { observer } from 'mobx-react-lite'
import { btecThankYouRoute, homeFeedRoute, pageRoute } from '../../../constants'
import { DatePicker } from '@mui/x-date-pickers'
import { parse } from 'date-fns'
import useMe from '../../Auth/useMe'


// @TODO: Once endpoint for checking if a user has already applied has been created. Check first and redirect home if they have.
interface User {
  fullName: string | null | undefined
  dob: string
  district: string | null | undefined
}

interface ApplicantDetailsProps {
  user: User
  dob: Date | null
  handleDobChange: (date: Date | null) => void
}

const ApplicantDetails: React.FC<ApplicantDetailsProps> = ({ user, dob, handleDobChange }) => (
  <Grid container sx={{ m: 0, mb: 5, border: '1px solid #e0e0e0', maxWidth: '1024px' }}>
    <Grid item xs={6} sx={{ p: 2, bgcolor: '#0000000a', borderBottom: '1px solid #e0e0e0' }}>
      <Typography variant="body1"><strong>Full Name</strong></Typography>
    </Grid>
    <Grid item xs={6} sx={{ p: 2, borderBottom: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}>
      <Typography variant="body1">{user.fullName}</Typography>
    </Grid>

    <Grid item xs={6} sx={{ p: 2, bgcolor: '#0000000a', borderBottom: '1px solid #e0e0e0' }}>
      <Typography variant="body1"><strong>DOB</strong></Typography>
    </Grid>
    <Grid item xs={6} sx={{ p: 2, borderBottom: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}>
      {user.dob ? (
        <Typography variant="body1">{user.dob}</Typography> // @TODO: Might need to be parsed when we know the format
      ) : (
        <DatePicker
          value={dob}
          onChange={handleDobChange}
          slotProps={{
            textField: {
              variant: 'standard',
            }
          }}
        />
      )}
    </Grid>

    <Grid item xs={6} sx={{ p: 2, bgcolor: '#0000000a' }}>
      <Typography variant="body1"><strong>District</strong></Typography>
    </Grid>
    <Grid item xs={6} sx={{ p: 2, borderLeft: '1px solid #e0e0e0' }}>
      <Typography variant="body1">{user.district}</Typography>
    </Grid>
  </Grid>
)

const BTECApplyPage = () => {
  const { data: profile } = useMe()
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('btec')
  const title = t('btecApply')

  const { palette } = useTheme()
  const { router } = useMst()
  const { pushRouteOrBringToTop, resetRoutes } = router

  const redirectToConfirmation = useCallback(() => {
    pushRouteOrBringToTop(btecThankYouRoute)
  }, [pushRouteOrBringToTop])

  // @TODO: resetRoutes if user already has applications.

  const user = {
    fullName: profile?.full_name,
    dob: '',
    district: profile?.district
  }

  const [dob, setDob] = useState(user.dob ? parse(user.dob, 'yyyy-MM-dd', new Date()) : null)
  const [isUnder16, setIsUnder16] = useState(false)
  const [isDobEntered, setIsDobEntered] = useState(!!user.dob)
  const [isChecked, setIsChecked] = useState(false)

  const handleDobChange = useCallback((date: Date | null) => {
    setDob(date)

    if (date) {
      setIsDobEntered(true)
      const birthDate = new Date(date)
      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }

      setIsUnder16(age < 16)
    } else {
      setIsDobEntered(false)
      setIsUnder16(false)
    }
  }, [])

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault()
    if (!isUnder16 && isDobEntered && isChecked) {
      redirectToConfirmation()
    }
  }, [isUnder16, isDobEntered, isChecked, redirectToConfirmation])

  const navigateToAboutBTEC = useCallback(async (e: React.MouseEvent) => {
    const aboutId = process.env.CONTENTFUL_ABOUT_ID ? process.env.CONTENTFUL_ABOUT_ID : '24uqunpuDwGo0DH5tVsNqv'
    e.preventDefault()
    e.stopPropagation()
    await pushRouteOrBringToTop(pageRoute(aboutId))
  }, [pushRouteOrBringToTop])

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <Box sx={{ maxWidth: '1024px', mt: 6, mx: 'auto', px: 4 }}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Apply for our BTEC Course
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you would like to apply to the CAMFED BTEC course please check your details below and select the optional modules you are interested in.
          </Typography>
        </Box>

        <Box sx={{ mb: 5 }}>
          <Typography variant="h5" gutterBottom>
            Applicant Details
          </Typography>
          <Typography variant="body2" gutterBottom>
            If any information is incorrect, update this on the settings page or contact a CAMFED colleague.
          </Typography>
        </Box>

        <ApplicantDetails user={user} dob={dob} handleDobChange={handleDobChange} />

        {isUnder16 && (
          <Typography variant="body2" color="error" gutterBottom>
            You must be 16 years of age to enroll onto this course.
          </Typography>
        )}

        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
          sx={{ mb: 2 }}
          label={
            <>
              Please confirm you have read the <Link onClick={navigateToAboutBTEC} sx={{ color: palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}>About BTEC</Link> page to ensure you have understood the course requirements.
            </>
          }
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '1024px' }}>
          <Button
            variant="contained"
            sx={{ display: 'flex' }}
            color="primary"
            onClick={handleSubmit}
            disabled={!isChecked || isUnder16 || !isDobEntered}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default observer(BTECApplyPage)