import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import useMst from '../../../../models/useMst';

interface DeleteBtecUnitLogParams {
  applicationId: string;
  unitId: string;
  logId: string;
}

const deleteBtecUnitLog = async ({ applicationId, unitId, logId }: DeleteBtecUnitLogParams) => {
  const response = await axios.delete(`/api/btec/applications/${applicationId}/units/${unitId}/logs/${logId}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response.data;
};

interface UseDeleteBtecUnitLogProps {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export default function useDeleteBtecUnitLog({ onSuccess, onError }: UseDeleteBtecUnitLogProps = {}) {
  const { messages } = useMst();
  const queryClient = useQueryClient();

  return useMutation(deleteBtecUnitLog, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['btecUnitLogs', variables.applicationId, variables.unitId]);
      queryClient.invalidateQueries(['btecApplication', variables.applicationId]);
      messages.showMessage('Log entry deleted successfully!', 'success');
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error, variables, context) => {
      queryClient.invalidateQueries(['btecUnitLogs', variables.applicationId, variables.unitId]);
      queryClient.invalidateQueries(['btecApplication', variables.applicationId]);
      messages.showMessage('Failed to delete log entry. Please try again.', 'error');
      if (onError) {
        onError(error);
      }
    }
  });
}