import { useUser } from '@auth0/nextjs-auth0/client'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { myProfileQueryKey } from '../../api/constants'
import { myAccountEndpoint } from '../../api/endpoints'
import { IUserMe } from '../../api/types'
import { useEffect } from 'react'

export default function useMe() {
  const { user } = useUser()

  return useQuery({
    queryKey: myProfileQueryKey(user?.email!),
    queryFn: async () => {
      const resp = await axios.get<{ data: IUserMe }>(myAccountEndpoint)
      return resp.data.data
    },
    refetchOnMount: 'always' // Ensures while there is an internet connection we refetch the data.
  })
}