import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface BtecApplicationUnit {
  btec_course_unit_id: string;
  title: string;
  status: string;
}

interface BtecApplication {
  btec_application_units: BtecApplicationUnit[];
}

const fetchBtecApplication = async (applicationId: string): Promise<BtecApplication> => {
  const response = await axios.get(`/api/btec/application/${applicationId}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response.data.data;
};

const btecApplicationQueryKey = (applicationId: string) => ['btecApplication', applicationId];

export default function useGetBtecApplication(applicationId: string, enabled: boolean = true) {
  return useQuery<BtecApplication>({
    queryKey: btecApplicationQueryKey(applicationId),
    queryFn: () => fetchBtecApplication(applicationId),
    refetchOnMount: 'always' // Ensures while there is an internet connection we refetch the data.
  });
}