import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface OptionalUnit {
  id: number;
  btec_course_id: number;
  title: string;
  description: string;
  is_mandatory: boolean;
  learning_hours: number;
  position: number;
}

// Define the fetch function
const fetchBtecOptionalUnits = async (applicationId: string): Promise<OptionalUnit[]> => {
  const response = await axios.get(`/api/btec/applications/${applicationId}/optional_units`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response.data.data;
};

// Define the query key constant (optional, but useful for cache management)
const btecOptionalUnitsQueryKey = (applicationId: string) => ['btecOptionalUnits', applicationId];

export default function useGetBtecOptionalUnits(applicationId: string, enabled: boolean = true) {
  return useQuery<OptionalUnit[]>({
    queryKey: btecOptionalUnitsQueryKey(applicationId),
    queryFn: () => fetchBtecOptionalUnits(applicationId),
    enabled,
  });
}
