import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface BtecApplication {
  id: string;
}

const fetchBtecApplications = async (): Promise<BtecApplication[]> => {
  const response = await axios.get('/api/btec/applications', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  return response.data.data.data;
};

const btecApplicationsQueryKey = ['btecApplications'];

export default function useGetBtecApplications(enabled: boolean = true) {
  return useQuery<BtecApplication[]>({
    queryKey: btecApplicationsQueryKey,
    queryFn: fetchBtecApplications,
    enabled,
  });
}