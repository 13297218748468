import { useEffect, useCallback, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import useMst from '../../models/useMst'
import { updateDetailsRoute } from '../../constants'
import useConfirmCheck from '../Auth/useConfirmCheck'
import { useRouter } from 'next/router'

interface UpdateCheckWrapperProps {
  children: ReactNode
}

const UpdateCheckWrapper = observer(({ children }: UpdateCheckWrapperProps) => {
  const { router } = useMst()
  const { pushRouteOrBringToTop } = router
  const nextRouter = useRouter()

  if (nextRouter.pathname === '/auth/logout') {
    return <>{children}</>
  }

  const { data: confirmCheckData, isLoading, isError } = useConfirmCheck()

  const redirectToUpdateDetails = useCallback(async () => {
    await pushRouteOrBringToTop(updateDetailsRoute)
  }, [pushRouteOrBringToTop])

  useEffect(() => {
    if (!isLoading && !isError && confirmCheckData !== 'no_content') {
      redirectToUpdateDetails()
    }
  }, [isLoading, isError, confirmCheckData, redirectToUpdateDetails])

  return <>{children}</>
})

export default UpdateCheckWrapper