// Query Keys
export const activeTimeQueryKey = (month: string, userIds: string[] | undefined) => ['activeTime', month, userIds]

export const betterWorldGroupsByTrainerIdQueryKey = (trainerId: string) => ['betterWorldGroupsByTrainerId', trainerId]

export const entriesBaseQueryKey = ['entries']
export const entriesQueryKey = (contentType: string, query?: unknown) => [...entriesBaseQueryKey, contentType, query]
export const entryQueryKey = (contentType: string, id: string | undefined, query?: unknown) => [
  ...entriesBaseQueryKey,
  contentType,
  id,
  query,
]
export const imagesBaseQueryKey = ['images']
export const bulkImagesQueryKey = (urls: string[]) => [...imagesBaseQueryKey, ...urls]

export const learnerGuidesInsightsBaseQueryKey = ['learnerGuidesInsights']

export const learnerGuidesInsightsQueryKey = (
  userIds: string[] | undefined,
  startDate: string | null | undefined,
  endDate: string | null | undefined
) => [...learnerGuidesInsightsBaseQueryKey, ...(userIds ?? []), startDate, endDate]

export const localesQueryKey = ['locales']

export const myProfileQueryKey = (userId: string | null | undefined) => ['myProfile', userId]

export const mySettingsQueryKey = (userId: string | null | undefined) => ['mySettings', userId]

export const myGuidesQueryKey = ['myGuides']

export const myGuideDetailsQueryKey = (guideId: string) => [...myGuidesQueryKey, guideId]

export const myBetterWorldGroupsQueryKey = ['myBetterWorldGroups']

export const myBetterWorldGroupQueryKey = (groupId: number) => [...myBetterWorldGroupsQueryKey, groupId]

const socialSupportCasesBaseQueryKey = ['socialSupportCases']

export const socialSupportCasesQueryKey = (userId: string) => [...socialSupportCasesBaseQueryKey, 'user', userId]

export const socialSupportCaseQueryKey = (caseId: number) => [...socialSupportCasesBaseQueryKey, 'case', caseId]

export const userReportsQueryKey = (userId: string | null | undefined) => ['userReports', userId]

export const btecApplicationsQueryKey = ['btecApplications']

export const btecApplicationQueryKey = (applicationId: string) => ['btecApplication', applicationId]
