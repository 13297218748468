import { useUser } from '@auth0/nextjs-auth0/client'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toJS } from 'mobx'
import { useTranslation } from 'next-i18next'
import { myProfileQueryKey } from '../../../../../api/constants'
import { myAccountEndpoint } from '../../../../../api/endpoints'
import { IUserMe, IAccountLocalData } from '../../../../../api/types'
import { accountProfileRouteName } from '../../../../../constants'
import useOfflineMutation from '../../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../../models/useMst'
import { trackEmailUpdated, trackPhoneUpdated } from '../../../../Analytics/trackers'
import { logError } from '../../../../Common/loggers'
import { useRouter } from 'next/router'

export const updateProfile = async (email?: string | null, phone_number?: string | null): Promise<IUserMe> => {
  const payload: { email?: string | null; phone_number?: string | null } = {}

  if (email) payload.email = email
  if (phone_number) payload.phone_number = phone_number

  const res = await axios.put<{ data: IUserMe }>(myAccountEndpoint, payload)
  return res.data.data
}

function optimisticUpdateMyProfile(queryClient: QueryClient, data: IAccountLocalData) {
  if (data) {
    const key = myProfileQueryKey(data.user_id);

    // optimistic update
    queryClient.setQueryData<IUserMe>(key, (profile: IUserMe | undefined) => {
      if (profile) {
        const { email, phone_number } = data;
        return {
          ...profile,
          email: email ?? null,
          phone_number: phone_number ?? null
        };
      }
    });

    // invalidate the query to trigger a refetch
    queryClient.invalidateQueries(key);
  }
}

export function useSyncProfile() {
  const { profile } = useMst();
  const queryClient = useQueryClient();
  const { user } = useUser();

  return async () => {
    try {
      const data = toJS(profile.localProfile);

      if (data) {
        // submit to backend
        const { email, phone_number } = data;
        await updateProfile(email, phone_number);

        // clear local data
        profile.didUpdateProfile();

        // optimistic update
        optimisticUpdateMyProfile(queryClient, data);

        if (user) {
          if (phone_number !== undefined) {
            trackPhoneUpdated(user, accountProfileRouteName);
          }
          if (email !== undefined) {
            trackEmailUpdated(user, accountProfileRouteName);
          }
        }
      }
    } catch (e) {
      logError(e);
    }
  };
}

export default function useMutateAndRedirect() {
  const { profile, messages } = useMst();
  const queryClient = useQueryClient();
  const { t } = useTranslation('account-profile');
  const syncProfile = useSyncProfile();

  return useOfflineMutation({
    addLocal: (data: IAccountLocalData) => {
      profile.enqueueForUpdate(data);
    },
    removeLocal: () => {
      profile.didUpdateProfile();
    },
    saveRemote: async (data: IAccountLocalData) => {
      const { email, phone_number } = data;
      try {
        await updateProfile(email, phone_number);
        return true;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data: IAccountLocalData, saved) => {
      if (saved) {
        optimisticUpdateMyProfile(queryClient, data);
        await syncProfile();
      }
    },
    onError: (e) => {
      const err = e as AxiosError;
      messages.showMessage(t('errorUpdating', { error: err.message }), 'error');
    },
  });
}