import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import useMst from '../../../../models/useMst'

interface SubmitOptionalUnitsParams {
  applicationId: string;
  optionalUnits: { id: number }[];
}

export const submitOptionalUnits = async ({ applicationId, optionalUnits }: SubmitOptionalUnitsParams) => {
  const response = await axios.post(`/api/btec/applications/${applicationId}/optional_units`, { optional_units: optionalUnits });
  return response.data;
};

interface UseSubmitOptionalUnitsProps {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export default function useSubmitOptionalUnits({ onSuccess, onError }: UseSubmitOptionalUnitsProps) {
  const { messages } = useMst();
  const queryClient = useQueryClient();

  return useMutation(submitOptionalUnits, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['btecApplication', variables.applicationId]);
      messages.showMessage('Optional modules updated successfully!', 'success');
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error, variables, context) => {
      messages.showMessage('Failed to add optional modules. Please try again.', 'error');
      if (onError) {
        onError(error);
      }
    }
  });
}