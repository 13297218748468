import debounce from 'lodash/debounce'
import useOnlineEffect from '../../hooks/offline/useOnlineEffect'
import { useSyncProfile } from '../pages/Account/Profile/hooks/useMutateMe'
import useSubmitUnsavedGroups from '../pages/MyBetterWorldGroups/hooks/useSubmitUnsavedGroups'
import useSubmitUnsavedSocialSupportCases from '../pages/SocialSupportCases/hooks/useSubmitUnsavedSocialSupportCases'

interface UpdateQueueProps { }

const UpdateQueue = ({ }: UpdateQueueProps) => {
  const submitUnsavedGroups = useSubmitUnsavedGroups()
  const submitUnsavedSocialSupportCases = useSubmitUnsavedSocialSupportCases()
  const syncProfile = useSyncProfile()

  useOnlineEffect(
    debounce(async () => {
      await Promise.all([
        syncProfile(),
        submitUnsavedGroups(),
        submitUnsavedSocialSupportCases(),
        // add more handlers here
      ])
    })
  )

  return null
}

export default UpdateQueue
