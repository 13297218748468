import { toJS } from 'mobx'
import { types } from 'mobx-state-tree'
import { IAccountLocalData } from '../api/types'

const Account = types
  .model({
    localAccount: types.maybe(types.frozen<IAccountLocalData>()),
  })
  .actions((self) => ({
    enqueueForUpdate(account: IAccountLocalData) {
      const data = toJS(self.localAccount)
      self.localAccount = { ...data, ...account }
    },
    didUpdateAccount() {
      self.localAccount = undefined
    },
  }))

export default Account
