import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import useMst from '../../../../models/useMst';
import { useTranslation } from 'next-i18next';

interface PostBtecUnitLogParams {
  applicationId: string;
  unitId: string;
  status: string;
  note?: string;
  file?: File;
}

async function postBtecUnitLog(params: PostBtecUnitLogParams) {
  const { applicationId, unitId, status, note, file } = params;

  const formData = new FormData();
  formData.append('applicationId', applicationId);
  formData.append('unitId', unitId);
  formData.append('status', status);
  if (note) {
    formData.append('note', note);
  }
  if (file) {
    formData.append('file', file);
  }

  // Assuming the endpoint is something like `/api/btec/unit-logs`
  const response = await axios.post(`/api/btec/applications/${applicationId}/units/${unitId}/logs`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}

export default function usePostBtecUnitLog() {
  const { messages } = useMst();
  const queryClient = useQueryClient();
  const { t } = useTranslation('btec-logs'); // Assuming you have translations set up

  return useMutation(postBtecUnitLog, {
    onError: (error: unknown) => {
      const err = error as AxiosError<any>;
      let errorMessage;

      if (err.response?.data?.errors?.note?.[0]) {
        errorMessage = 'Error: ' + err.response.data.errors.note[0];
      } else if (err.response?.data?.errors?.file?.[0]) {
        errorMessage = 'Error: ' + err.response.data.errors.file[0];
      } else if (err.response?.data?.message) {
        errorMessage = 'Error: ' + err.response.data.message;
      } else {
        errorMessage = 'Error updating. Please try again.';
      }
      
      // Assuming you have a messages system similar to the one in useMutateMe
      messages.showMessage(errorMessage, 'error');
    },
    onSuccess: (data) => {
      // Handle success, e.g., show a success message
      messages.showMessage('Unit updated successfully', 'success');

      // Optionally, you can update the cache or invalidate queries to refetch data
      queryClient.invalidateQueries(['btec-logs', data.applicationId, data.unitId]);
    },
  });
}