import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import {
  Box,
  Button,
  IconButton,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Select,
  OutlinedInput,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import AppBarBackButton from '../../AppBarBackButton'
import { INavigator } from '../../Navigation/types'
import useNavigator from '../../Navigation/useNavigator'
import { palette } from '../../../theme/palette'
import { Delete, Close } from '@mui/icons-material'
import { useQueryClient } from '@tanstack/react-query'
import useGetBtecUnitLogs from './hooks/useGetBtecUnitLogs'
import usePostBtecUnitLog from './hooks/usePostBtecUnitLog'
import useDeleteBtecUnitLog from './hooks/useDeleteBtecUnitLog'
import useMst from '../../../models/useMst'

const BTECSingleUnitPage = (props: { data?: any; id?: any }) => {
  const { messages } = useMst()
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('btec')
  const { id } = props
  const { applicationId, name } = props.data ?? {}

  const queryClient = useQueryClient()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<'upload' | 'note' | 'status' | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [note, setNote] = useState('')
  const [status, setStatus] = useState('Not Started')
  const [isUploading, setIsUploading] = useState(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [rowToDelete, setRowToDelete] = useState<any>(null)

  const { data: unitLogs, error: unitLogsError, isLoading: unitLogsLoading } = useGetBtecUnitLogs(applicationId, id)
  const postBtecUnitLogMutation = usePostBtecUnitLog()
  const deleteBtecUnitLogMutation = useDeleteBtecUnitLog()

  const handleDelete = (row: any) => {
    setRowToDelete(row)
    setIsDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setRowToDelete(null)
    setIsDeleteModalOpen(false)
  }

  const confirmDelete = () => {
    if (rowToDelete) {
      deleteBtecUnitLogMutation.mutate(
        {
          applicationId,
          unitId: id,
          logId: rowToDelete,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['btecUnitLogs', applicationId, id])
            queryClient.invalidateQueries(['btecApplication', applicationId])
          },
          onError: (error) => {
            queryClient.invalidateQueries(['btecUnitLogs', applicationId, id])
            queryClient.invalidateQueries(['btecApplication', applicationId])
            console.error('Failed to delete log:', error)
          },
        }
      )
    }
    closeDeleteModal()
  }

  const openModal = (content: 'upload' | 'note' | 'status') => {
    setModalContent(content)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setModalContent(null)
    setFile(null)
    setNote('')
    setStatus('Not Started')
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null)
  }

  const handleFileRemove = () => {
    setFile(null)
  }

  const handleSave = () => {
    setIsUploading(true)

    postBtecUnitLogMutation.mutate({
      applicationId,
      unitId: id,
      status,
      note,
      file: file || undefined,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['btecUnitLogs', applicationId, id])
        queryClient.invalidateQueries(['btecApplication', applicationId])
        setIsUploading(false)
        closeModal()
      },
      onError: (error: unknown) => {
        setIsUploading(false)
      },
    })
  }

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={name} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}
    >
      <Box sx={{ maxWidth: '1200px', mt: 6, mx: 'auto', px: 4, width: '100%' }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant='h4' gutterBottom>
            {name}
          </Typography>
        </Box>
        {unitLogsLoading ? (
          <Typography>Loading...</Typography>
        ) : unitLogsError ? (
          <Typography>Error loading unit logs: {unitLogsError.message}</Typography>
        ) : unitLogs && unitLogs.length > 0 ? (
          <TableContainer sx={{ mb: 6 }}>
            <Table
              sx={{ borderTop: { xs: 'none', md: '1px solid #E0E0E0' }, borderLeft: { xs: 'none', md: '1px solid #E0E0E0' }, borderRight: { xs: 'none', md: '1px solid #E0E0E0' }, borderCollapse: 'separate' }}
            >
              <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
                <TableRow>
                  <TableCell
                    sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '15%' }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '10%' }}
                  >
                    Upload File
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '60%' }}
                  >
                    Note
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '10%' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ height: '64px', padding: '12px', width: '5%' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitLogs.map((row) => (
                  <TableRow key={row.id} sx={{ display: { xs: 'flex', md: 'table-row' }, flexDirection: 'column' }}>
                    <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', backgroundColor: { xs: '#f9f9f9', md: 'initial' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px' }}>
                      {new Date(row.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: { xs: '1px solid #E0E0E0', md: 'none' },
                        borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' },
                        borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' },
                        height: '64px',
                        padding: '12px',
                        textAlign: 'center',
                        backgroundColor: row.file_url ? 'inherit' : '#E0E0E0',
                      }}
                    >
                      {row.file_url ? (
                        <Link
                          href={row.file_url}
                          sx={{ color: palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}
                          download
                          target='_blank'
                        >
                          View
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px' }}>
                      {row.note}
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px' }}>
                      {row.status}
                    </TableCell>
                    <TableCell
                      sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px', mb: { xs: '12px', md: '0px' }, textAlign: 'center' }}
                    >
                      <IconButton onClick={() => handleDelete(row.id)} aria-label='delete'>
                        <Delete sx={{ color: palette.primary.main }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ''
        )}
        <Box sx={{ mb: 5 }}>
          <Typography variant='body1' gutterBottom>
            Please upload any work for this unit or update the overall status of this unit.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mb: 5 }}>
          <Button sx={{ mr: 3 }} variant='contained' color='primary' onClick={() => openModal('upload')}>
            Upload File*
          </Button>
          <Button sx={{ mr: 3 }} variant='contained' color='primary' onClick={() => openModal('note')}>
            Note
          </Button>
          <Button sx={{ mr: 3 }} variant='contained' color='primary' onClick={() => openModal('status')}>
            Update Status
          </Button>
        </Box>
        <Box sx={{ mb: 5 }}>
          <Typography variant='body1' gutterBottom>
            *Files accepted are: bmp, gif, jpg, jpeg, png, tiff, csv, doc, docx, odp, ods, odt, pdf, ppt, pptx, rtf, txt, xls, xlsx, avi, mp4, mpeg, ogv under 10mb.
          </Typography>
        </Box>
      </Box>

      <Modal open={isModalOpen} onClose={closeModal} sx={{ zIndex: 1400 }}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          {isUploading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Uploading...</Typography>
            </Box>
          ) : (
            <Box>
              {modalContent === 'upload' && (
                <Box>
                  <Typography variant='h6' gutterBottom>Upload File</Typography>
                  <TextField
                    type='file'
                    fullWidth
                    onChange={handleFileChange}
                    sx={{ mb: 2 }}
                  />
                  {file && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Typography variant='body2'>{file.name}</Typography>
                      <IconButton onClick={handleFileRemove} sx={{ ml: 1 }} aria-label='remove file'>
                        <Close />
                      </IconButton>
                    </Box>
                  )}
                  <TextField
                    label='Note'
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    displayEmpty
                    native
                    input={<OutlinedInput />}
                  >
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Complete">Complete</option>
                  </Select>
                </Box>
              )}
              {modalContent === 'note' && (
                <Box>
                  <Typography variant='h6' gutterBottom>Note</Typography>
                  <TextField
                    label='Note'
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    displayEmpty
                    native
                    input={<OutlinedInput />}
                  >
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Complete">Complete</option>
                  </Select>
                </Box>
              )}
              {modalContent === 'status' && (
                <Box>
                  <Typography variant='h6' gutterBottom>Update Status</Typography>
                  <Select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    displayEmpty
                    native
                    input={<OutlinedInput />}
                  >
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Complete">Complete</option>
                  </Select>
                </Box>
              )}
              <Box sx={{ mt: 4 }}>
                <Button variant='contained' color='primary' onClick={handleSave} disabled={isUploading}>
                  Save
                </Button>
                <Button variant='text' color='primary' onClick={closeModal} sx={{ ml: 2 }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      <Modal open={isDeleteModalOpen} onClose={closeDeleteModal} sx={{ zIndex: 1400 }}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography variant='h6' gutterBottom>
            Do you really want to delete this row and any corresponding data?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant='text' color='primary' onClick={closeDeleteModal}>
              No, cancel this request
            </Button>
            <Button variant='contained' color='primary' onClick={confirmDelete} sx={{ ml: 2 }}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </AuthLayout>
  )
}


export default observer(BTECSingleUnitPage)