import React, { useCallback, useEffect } from 'react'
import { Box, Hidden, Typography, Tooltip, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Link, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import InfoIcon from '@mui/icons-material/Info'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import AppBarBackButton from '../../AppBarBackButton'
import { INavigator } from '../../Navigation/types'
import useNavigator from '../../Navigation/useNavigator'
import { palette } from '../../../theme/palette'
import { useTranslation } from 'react-i18next'
import useMst from '../../../models/useMst'
import { btecOptionalUnitsRoute, btecSingleUnitRoute } from '../../../constants'
import useGetBtecApplications from './hooks/useGetBtecApplications'
import useGetBtecApplication from './hooks/useGetBtecApplication'
import useMe from '../../Auth/useMe'

interface Unit {
  id: string
  unit: string
  name: string
  summary: string
  status: string
}

interface BtecApplication {
  id: string
}

interface ErrorWithMessage {
  message: string
}

const BTECCourseManagementPage = () => {
  const { data: profile } = useMe()
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('btec')
  const title = t('btecCourseManagement')
  const { router } = useMst()
  const { resetRoutes } = router
  const { pushRouteOrBringToTop } = router

  const { data: applications, error, isLoading, isError, refetch: refetchApplications } = useGetBtecApplications(true)

  const getLastId = (applications: BtecApplication[]): string | null => {
    if (applications && applications.length > 0) {
      return applications[applications.length - 1].id.toString()
    }
    return null
  }

  useEffect(() => {
    if (profile && profile.btec) {
      if (
        profile.btec.btecStatus === 'Withdrawn' ||
        profile.btec.btecStatus === null ||
        (profile.btec.btecWithdrawalDate && new Date(profile.btec.btecWithdrawalDate) > new Date())
      ) {
        resetRoutes()
      }
    }
  }, [profile, navigator, resetRoutes])

  const applicationId = getLastId(applications ?? [])
  const { data: applicationData, error: applicationError, isLoading: applicationIsLoading, isError: applicationIsError, refetch: refetchApplication } = useGetBtecApplication(applicationId ?? '', !!applicationId)

  useEffect(() => {
    refetchApplications()
    if (applicationId) {
      refetchApplication()
    }
  }, [applicationId, refetchApplications, refetchApplication])

  const renderLoadingSpinner = (message: string) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>{message}</Typography>
    </Box>
  )

  const handleEditUnit = useCallback((id: string, name: string, unit: string, applicationId: string) => {
    pushRouteOrBringToTop(btecSingleUnitRoute(id, name, unit, applicationId))
  }, [pushRouteOrBringToTop])

  const { mandatoryUnits, optionalUnits } = (applicationData?.btec_application_units || []).reduce<{ mandatoryUnits: Unit[], optionalUnits: Unit[] }>((acc, unit: any) => {
    const unitData: Unit = {
      id: unit.id.toString(),
      unit: unit.btec_course_unit_id,
      name: unit.title,
      summary: unit.course_unit.description,
      status: unit.status,
    }

    if (unit.is_mandatory) {
      acc.mandatoryUnits.push(unitData)
    } else {
      acc.optionalUnits.push(unitData)
    }

    return acc
  }, { mandatoryUnits: [], optionalUnits: [] })

  const navigateToOptionalUnits = useCallback(() => {
    pushRouteOrBringToTop(btecOptionalUnitsRoute(optionalUnits))
    refetchApplication()
  }, [optionalUnits, pushRouteOrBringToTop, refetchApplication])

  if (isLoading) {
    return renderLoadingSpinner('Fetching your applications...')
  }

  if (isError || !applicationId) {
    return (
      <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
        <Box sx={{ maxWidth: '1280px', width: '100%', my: 6, mx: 'auto', px: 4 }}>
          <Typography variant="h4">Course Management</Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 6 }}>
            No application could be found.
          </Typography>
        </Box>
      </AuthLayout>
    )
  }

  if (applicationIsLoading) {
    return renderLoadingSpinner('Fetching your units...')
  }

  if (applicationIsError || !applicationData?.btec_application_units?.length) {
    return (
      <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
        <Box sx={{ maxWidth: '1280px', width: '100%', my: 6, mx: 'auto', px: 4 }}>
          <Typography variant="h4">Course Management</Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 6 }}>
            No units could be found.
          </Typography>
        </Box>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <Box sx={{ maxWidth: '1280px', width: '100%', my: 6, mx: 'auto', px: 4 }}>
        <Typography variant="h4">Course Management</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 6 }}>
          Please see the below BTEC modules that you need to complete to achieve your qualification. Please use this area of the hub
          to manage your progress through each module and as an online storage for your work. This area is to support your learning
          and will not be accessible by your assessors.
        </Typography>
        <Typography variant="h5" sx={{ mb: 4 }}>
          Mandatory Units
        </Typography>
        <TableContainer sx={{ mb: 6 }}>
          <Table sx={{ borderTop: { xs: 'none', md: '1px solid #E0E0E0' }, borderLeft: { xs: 'none', md: '1px solid #E0E0E0' }, borderRight: { xs: 'none', md: '1px solid #E0E0E0' }, borderCollapse: 'separate' }}>
            <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
              <TableRow>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '5%' }}>Unit</TableCell>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '80%' }}>Title</TableCell>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '20%' }}>Status</TableCell>
                <TableCell sx={{ height: '64px', padding: '12px', width: '20%' }}>Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mandatoryUnits.map((unit) => (
                <TableRow key={unit.id} sx={{ display: { xs: 'flex', md: 'table-row' }, flexDirection: 'column' }}>
                  <Hidden mdDown><TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', backgroundColor: { xs: '#f9f9f9', md: 'initial' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px' }}>{unit.unit}</TableCell></Hidden>
                  <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                    {unit.name}
                    <Tooltip title={unit.summary} arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: getStatusStyle(unit.status).backgroundColor, color: getStatusStyle(unit.status).textColor, display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                    {unit.status}
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', height: '64px', padding: '12px', mb: { xs: '12px', md: '0px' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, }}>
                    <Button onClick={() => handleEditUnit(unit.id, unit.name, unit.unit, applicationId ?? '')} variant="outlined">
                      View/Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Optional Units
        </Typography>
        {optionalUnits.length > 0 ? (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}><Link onClick={navigateToOptionalUnits} sx={{ color: palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}>Click here to edit your optional units</Link></Typography>
            <TableContainer sx={{ mb: 6 }}>
              <Table sx={{ borderTop: { xs: 'none', md: '1px solid #E0E0E0' }, borderLeft: { xs: 'none', md: '1px solid #E0E0E0' }, borderRight: { xs: 'none', md: '1px solid #E0E0E0' }, borderCollapse: 'separate' }}>
                <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
                  <TableRow>
                    <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '5%' }}>Unit</TableCell>
                    <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '80%' }}>Title</TableCell>
                    <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '20%' }}>Status</TableCell>
                    <TableCell sx={{ height: '64px', padding: '12px', width: '20%' }}>Update</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionalUnits.map((unit) => (
                    <TableRow key={unit.id} sx={{ display: { xs: 'flex', md: 'table-row' }, flexDirection: 'column' }}>
                      <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', backgroundColor: { xs: '#f9f9f9', md: 'initial' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: { md: '64px' }, padding: '12px' }}>{unit.unit}</TableCell>
                      <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                        {unit.name}
                        <Tooltip title={unit.summary} arrow>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: getStatusStyle(unit.status).backgroundColor, color: getStatusStyle(unit.status).textColor, display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                        {unit.status}
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: 'center', height: '64px', padding: '12px', mb: { xs: '12px', md: '0px' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, }}>
                        <Button onClick={() => handleEditUnit(unit.id, unit.name, unit.unit, applicationId ?? '')} variant="outlined">
                          View/Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1">You need to select up to 3 optional modules with learning hours equal to over 50 hours to complete the BTEC Course. <Link onClick={navigateToOptionalUnits} sx={{ color: palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}>Click here to add your optional units</Link></Typography>
        )}
      </Box>
    </AuthLayout>
  )
}

const getStatusStyle = (status: string) => {
  switch (status) {
    case 'Complete':
      return { backgroundColor: '#CCE8CD', textColor: '#1E4620' }
    case 'In Progress':
      return { backgroundColor: '#FFF4E5', textColor: '#663C00' }
    default:
      return { backgroundColor: 'transparent', textColor: '#000000de' }
  }
}

export default observer(BTECCourseManagementPage)