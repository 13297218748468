import { toJS } from 'mobx'
import { types } from 'mobx-state-tree'
import { IAccountLocalData } from '../api/types'

const Profile = types
  .model({
    localProfile: types.maybe(types.frozen<IAccountLocalData>()),
  })
  .actions((self) => ({
    enqueueForUpdate(profile: IAccountLocalData) {
      const data = toJS(self.localProfile)
      self.localProfile = { ...data, ...profile }
    },
    didUpdateProfile() {
      self.localProfile = undefined
    },
  }))

export default Profile
