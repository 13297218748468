import React, { useEffect } from 'react'
import { Typography, TextField, Grid, Box, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material'
import { countryCodes } from '../../../constants'

interface PhoneNumberChangeProps {
  currentPhoneNumber: string
  newPhoneNumber: { countryCode: string; number: string }
  confirmNewPhoneNumber: { countryCode: string; number: string }
  onNewPhoneNumberChange: (field: string, value: string) => void
  onConfirmNewPhoneNumberChange: (field: string, value: string) => void
  errorMessage: string
  country: string
}

const PhoneNumberChange: React.FC<PhoneNumberChangeProps> = ({
  currentPhoneNumber,
  newPhoneNumber,
  confirmNewPhoneNumber,
  onNewPhoneNumberChange,
  onConfirmNewPhoneNumberChange,
  errorMessage,
  country
}) => {
  useEffect(() => {
    const userCountryCode = countryCodes.find(cc => cc.country === country)?.code || ''
    if (userCountryCode && !newPhoneNumber.countryCode) {
      onNewPhoneNumberChange('countryCode', userCountryCode)
      onConfirmNewPhoneNumberChange('countryCode', userCountryCode)
    }
  }, [country, onNewPhoneNumberChange, onConfirmNewPhoneNumberChange, newPhoneNumber.countryCode])

  const handleNewPhoneNumberChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onNewPhoneNumberChange(field, e.target.value)
  }

  const handleConfirmNewPhoneNumberChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onConfirmNewPhoneNumberChange(field, e.target.value)
  }

  const handleCountryCodeChange = (e: SelectChangeEvent<string>, field: string) => {
    onNewPhoneNumberChange(field, e.target.value as string)
  }

  const handleConfirmCountryCodeChange = (e: SelectChangeEvent<string>, field: string) => {
    onConfirmNewPhoneNumberChange(field, e.target.value as string)
  }

  const isPhoneNumberEmpty = !currentPhoneNumber || currentPhoneNumber === ''

  // Determine the user's country code
  const userCountryCode = countryCodes.find(cc => cc.country === country)?.code || ''
  const sortedCountryCodes = [
    ...countryCodes.filter(cc => cc.code === userCountryCode),
    ...countryCodes.filter(cc => cc.code !== userCountryCode)
  ]

  return (
    <Box mb={3}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              Phone Number Change
            </Typography>
            {isPhoneNumberEmpty ? (
              <Typography variant="body1" color="textSecondary">
                We do not currently have a phone number saved against your account. Please add your phone number below. Once this change has been accepted you will be able to login with SMS.
              </Typography>
            ) : (
              <TextField
                label="Current Phone Number"
                value={currentPhoneNumber}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="p" gutterBottom>
              Add Number
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={3}>
                <FormControl fullWidth margin="normal" error={!!errorMessage}>
                  <InputLabel id="country-code-label">Country Code</InputLabel>
                  <Select
                    labelId="country-code-label"
                    label="Country Code"
                    value={newPhoneNumber.countryCode}
                    onChange={(e) => handleCountryCodeChange(e, 'countryCode')}
                  >
                    {sortedCountryCodes.map((cc) => (
                      <MenuItem key={cc.code} value={cc.code}>
                        {cc.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={9}>
                <TextField
                  label="Add Number"
                  value={newPhoneNumber.number}
                  onChange={handleNewPhoneNumberChange('number')}
                  fullWidth
                  margin="normal"
                  error={!!errorMessage}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="p" gutterBottom>
              Confirm Number
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={3}>
                <FormControl fullWidth margin="normal" error={!!errorMessage}>
                  <InputLabel id="country-code-label-confirm">Country Code</InputLabel>
                  <Select
                    labelId="country-code-label-confirm"
                    label="Country Code"
                    value={confirmNewPhoneNumber.countryCode}
                    onChange={(e) => handleConfirmCountryCodeChange(e, 'countryCode')}
                  >
                    {sortedCountryCodes.map((cc) => (
                      <MenuItem key={cc.code} value={cc.code}>
                        {cc.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={9}>
                <TextField
                  label="Confirm Number"
                  value={confirmNewPhoneNumber.number}
                  onChange={handleConfirmNewPhoneNumberChange('number')}
                  fullWidth
                  margin="normal"
                  error={!!errorMessage}
                  helperText={errorMessage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default PhoneNumberChange