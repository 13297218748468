import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { menuZIndex } from '../../constants'

export interface IControlledSelectProps<T = string> {
  value: T
  onChange: (event: SelectChangeEvent<T>, child: ReactNode) => void
  options: Array<{ value: string; label: string }>
  label?: string
  labelId?: string
  name?: string
  id?: string
  className?: string
  disabled?: boolean
  required?: boolean
  multiple?: boolean
  fullWidth?: boolean
  error?: boolean
}

const ControlledSelect = <ValueType,>(props: IControlledSelectProps<ValueType>): JSX.Element => {
  const {
    value,
    onChange,
    options,
    label,
    labelId,
    name,
    id,
    className,
    disabled,
    required,
    multiple,
    fullWidth = true,
    error
  } = props

  const { typography } = useTheme()

  return (
    <FormControl className={className} required={required} disabled={disabled} fullWidth={fullWidth} error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        name={name}
        multiple={multiple}
        label={label}
        labelId={labelId}
        id={id}
        value={value}
        onChange={onChange}
        sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}
        MenuProps={{
          sx: { zIndex: menuZIndex },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ControlledSelect