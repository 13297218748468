// Intro.tsx
import React from 'react'
import { Typography, Box } from '@mui/material'

const Intro: React.FC = () => {
  return (
    <Box mb={3}>
      <Typography variant="h5" component="h1" gutterBottom>
        To get the best experience from the Camfed app, update your contact details now.
      </Typography>
      <Typography variant="body1">
        A little information about this before you start! Your contact details must be unique and only accessible by you.
      </Typography>
    </Box>
  )
}

export default Intro