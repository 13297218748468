import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface UnitLog {
  id: number;
  status: string;
  note?: string;
  created_at: string;
  updated_at: string;
  file_url?: string;
}

const fetchBtecUnitLogs = async (applicationId: string, unitId: string): Promise<UnitLog[]> => {
  const response = await axios.get(`/api/btec/applications/${applicationId}/units/${unitId}/logs`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response.data.data;
};

const btecUnitLogsQueryKey = (applicationId: string, unitId: string) => ['btecUnitLogs', applicationId, unitId];

export default function useGetBtecUnitLogs(applicationId: string, unitId: string, enabled: boolean = true) {
  return useQuery<UnitLog[], AxiosError>({
    queryKey: btecUnitLogsQueryKey(applicationId, unitId),
    queryFn: () => fetchBtecUnitLogs(applicationId, unitId),
    enabled,
  });
}
