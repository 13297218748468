// /Auth/useConfirmCheck.ts

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { confirmCheckEndpoint } from '../../api/endpoints'

export default function useConfirmCheck() {
  return useQuery({
    queryKey: ['confirmCheck'],
    queryFn: async () => {
      const resp = await axios.get(confirmCheckEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      if (resp.status === 204) {
        return 'no_content'
      }
      return resp.data
    },
    retry: false
  })
}