import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

const FullScreenSpinner = ({ loading }: { loading: boolean }) => {
  return (
    <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default FullScreenSpinner