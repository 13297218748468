import { useState, useEffect, ReactElement, useCallback } from 'react'
import { useUser } from '@auth0/nextjs-auth0/client'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { Button, CircularProgress, DialogContent, Divider, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useQueryClient } from '@tanstack/react-query'
import useIsOnline from '../../../../hooks/offline/useIsOnline'
import useMst from '../../../../models/useMst'
import { useAnalytics } from '../../../Analytics/AnalyticsContext'
import AppBarBackButton from '../../../AppBarBackButton'
import useLogout from '../../../Auth/useLogout'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import useShowMessage from '../../../Common/useShowMessage'
import { IScreenProps } from '../../../Navigation/types'
import useNavigator from '../../../Navigation/useNavigator'
import BodyText from '../Common/BodyText'
import IconTextRow from '../Common/IconTextRow'
import SectionHeading from '../Common/SectionHeading'
import useMe from '../../../Auth/useMe'
import useMutateMe from '../Profile/hooks/useMutateMe'
import usePrompt from '../../../Common/usePrompt'

interface AccountSettingsPageProps extends IScreenProps { }

const ChangePasswordButton = ({ className = '', onClick }: { className?: string; onClick: () => void }) => {
  const { t } = useTranslation('account-settings')

  return (
    <Button className={className} onClick={onClick} variant="contained">
      {t('changePassword')}
    </Button>
  )
}

const LogoutButton = ({ className = '' }: { className?: string }): ReactElement => {
  const { logoutWithConfirmation, logoutDialog } = useLogout()
  const online = useIsOnline()
  const { t } = useTranslation('account-settings')
  const { typography } = useTheme()

  return (
    <>
      <Button disabled={!online} className={className} onClick={logoutWithConfirmation} variant="contained">
        {t('logOutNow')}
      </Button>
      {!online ? (
        <Typography className="mx-4 mb-3" sx={{ ...typography.caption.error.dark }}>
          {t('offlineCannotLogout')}
        </Typography>
      ) : null}
      {logoutDialog}
    </>
  )
}

const AccountSettingsPage = observer(({ }: AccountSettingsPageProps) => {
  const { user } = useUser()
  const { auth } = useMst()
  const navigator = useNavigator()
  const { showMessage, messageDialog } = useShowMessage()
  const { data: profile, refetch } = useMe()
  const queryClient = useQueryClient()
  const { trackChangePassword } = useAnalytics()
  const { t } = useTranslation('account-settings')
  const { t: tAuth } = useTranslation('auth')
  const { t: tCommon } = useTranslation('common')
  const title = t('accountSettings')

  const email = user?.email

  const [phoneNumberStatus, setPhoneNumberStatus] = useState(profile?.phone_number ?? '')
  const [previousPhoneNumber, setPreviousPhoneNumber] = useState(profile?.phone_number ?? '')
  const [pendingPhoneNumber, setPendingPhoneNumber] = useState<string | null>(null)

  const [emailStatus, setEmailStatus] = useState(profile?.email ?? '')
  const [previousEmail, setPreviousEmail] = useState(profile?.email ?? '')
  const [pendingEmail, setPendingEmail] = useState<string | null>(null)

  const online = useIsOnline()
  useEffect(() => {
    if (online) {
      // Delay the refetch by 1 second to allow the new data to be set.
      setTimeout(() => {
        refetch()
      }, 1000)
    }
  }, [online, refetch])

  const handleChangePassword = useCallback(async () => {
    if (email) {
      showMessage({
        buttons: [
          {
            text: tCommon('cancel') ?? '',
          },
          {
            text: tCommon('confirm') ?? '',
            onClick: async () => {
              showMessage({
                buttons: [], // no buttons
                content: (
                  <DialogContent className="text-center py-4">
                    <CircularProgress />
                  </DialogContent>
                ),
                title: tAuth('requestingPasswordReset') ?? '',
              })

              const message = await auth.requestChangePassword(email)

              trackChangePassword()

              showMessage({
                buttons: [{ text: tCommon('okay') ?? '' }],
                message,
                title: tCommon('success') ?? '',
              })
            },
          },
        ],
        message: tAuth('changePasswordConfirmation') ?? '',
        title: tAuth('changePasswordConfirmationTitle') ?? '',
      })
    }
  }, [auth, email, showMessage, tAuth, tCommon, trackChangePassword])

  const { mutate } = useMutateMe()

  const { trackPhoneUpdated, trackEmailUpdated } = useAnalytics()

  // Update the phone number state to use in the prompt
  const { showPrompt: changePhoneNumber, promptDialog: phoneNumberDialog } = usePrompt({
    initialValue: phoneNumberStatus,
    inputLabel: t('phoneNumber'),
    tooltip: 'Please include your country code. For example, +233123456789.',
    onConfirm: useCallback(
      (contact_phone_number: string) => {
        setPreviousPhoneNumber(phoneNumberStatus)
        setPendingPhoneNumber(contact_phone_number)
        if (profile?.id) {
          mutate({
            data: { user_id: String(profile.id), phone_number: contact_phone_number },
            onSuccess(data, saved) {
              if (saved) {
                setPhoneNumberStatus(contact_phone_number)
                setPendingPhoneNumber(null)
                trackPhoneUpdated()
                queryClient.invalidateQueries(['me']) // Invalidate the query to refetch the profile data
                refetch()
              }
            },
            onError() {
              setPendingPhoneNumber(null)
            }
          })
        }
      },
      [mutate, profile?.id, trackPhoneUpdated, phoneNumberStatus, queryClient, refetch]
    ),
    title: t('phoneNumber') ?? '',
  })

  // Update the email state to use in the prompt
  const { showPrompt: changeEmail, promptDialog: emailDialog } = usePrompt({
    initialValue: emailStatus,
    inputLabel: t('emailAddress'),
    onConfirm: useCallback(
      (contact_email: string) => {
        setPreviousEmail(emailStatus)
        setPendingEmail(contact_email)
        if (profile?.id) {
          mutate({
            data: { user_id: String(profile.id), email: contact_email },
            onSuccess(data, saved) {
              if (saved) {
                setEmailStatus(contact_email)
                setPendingEmail(null)
                trackEmailUpdated()
                queryClient.invalidateQueries(['me']) // Invalidate the query to refetch the profile data
                refetch()
              }
            },
            onError() {
              setPendingEmail(null)
            }
          })
        }
      },
      [mutate, profile?.id, trackEmailUpdated, emailStatus, queryClient, refetch]
    ),
    title: t('emailAddress') ?? '',
  })

  // Handle initial load
  useEffect(() => {
    if (profile) {
      setPhoneNumberStatus(profile.phone_number ?? '')
      setPreviousPhoneNumber(profile.phone_number ?? '')
      setEmailStatus(profile.email ?? '')
      setPreviousEmail(profile.email ?? '')
    }
  }, [profile])

  // Handle updates after refetch
  useEffect(() => {
    if (profile) {
      if (pendingPhoneNumber && profile.phone_number === pendingPhoneNumber) {
        setPendingPhoneNumber(null)
      }
      if (pendingEmail && profile.email === pendingEmail) {
        setPendingEmail(null)
      }
    }
  }, [profile, pendingPhoneNumber, pendingEmail])

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <div className="flex flex-col py-4">
        <div>
          <ListSectionTitle className="mx-4 mb-1">{t('loginDetails')}</ListSectionTitle>
          <IconTextRow
            onClick={!pendingPhoneNumber ? changePhoneNumber : undefined}
            actionText={pendingPhoneNumber ? 'Pending Change' : (profile?.phone_number ? t('changePhone') : t('addPhone'))}
            label={t('phoneNumber')}
            detailText={
              pendingPhoneNumber ? (
                <div>
                  {previousPhoneNumber}
                  <Typography variant="caption" color="textSecondary" className="block">
                    Pending Change: {pendingPhoneNumber}
                  </Typography>
                </div>
              ) : (
                phoneNumberStatus
              )
            }
            icon={<PhoneIphoneIcon />}
          />
          <IconTextRow
            onClick={!pendingEmail ? changeEmail : undefined}
            actionText={pendingEmail ? 'Pending Change' : (profile?.email ? t('changeEmail') : t('addEmail'))}
            label={t('emailAddress')}
            detailText={
              pendingEmail ? (
                <div>
                  {previousEmail}
                  <Typography variant="caption" color="textSecondary" className="block">
                    Pending Change: {pendingEmail}
                  </Typography>
                </div>
              ) : (
                emailStatus
              )
            }
            icon={<MailIcon />}
          />
        </div>
        <Divider className="my-4" />
        <div>
          <SectionHeading className="mx-4 mb-1">{t('changeYourPassword')}</SectionHeading>
          <BodyText className="mx-4 mb-3 whitespace-pre-wrap">{t('changePasswordDescription')}</BodyText>
          <ChangePasswordButton className="mx-4" onClick={handleChangePassword} />
        </div>
        <Divider className="my-4" />
        <div>
          <SectionHeading className="mx-4 mb-1">{t('onASharedDevice?')}</SectionHeading>
          <BodyText className="mx-4 mb-3">{t('onASharedDeviceDescription')}</BodyText>
          <LogoutButton className="mx-4" />
        </div>
      </div>
      {messageDialog}
      {phoneNumberDialog}
      {emailDialog}
    </AuthLayout>
  )
})

export default AccountSettingsPage