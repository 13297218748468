import '@formatjs/intl-locale/polyfill'

import '@formatjs/intl-datetimeformat/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-listformat/polyfill'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-relativetimeformat/polyfill'

import '@formatjs/intl-datetimeformat/locale-data/en'
import '@formatjs/intl-datetimeformat/locale-data/sw'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/sw'
import '@formatjs/intl-listformat/locale-data/en'
import '@formatjs/intl-listformat/locale-data/sw'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-numberformat/locale-data/sw'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/sw'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/sw'

import { CacheProvider, EmotionCache } from '@emotion/react'

import { UserProvider } from '@auth0/nextjs-auth0/client'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import NoSSR from '../components/NoSSR'
import MobxProvider from '../models/MobxProvider'

import axios from 'axios'
import { meEndpoint } from '../api/endpoints'
import ActivityTracker from '../components/Analytics/ActivityTracker'
import { initTrackers } from '../components/Analytics/trackers'
import LocalePathPatcher from '../components/Languages/LocalePathPatcher'
import NavigationHistory from '../components/Navigation/NavigationHistory'
import UpdateQueue from '../components/UpdateQueue/UpdateQueue'
import '../styles/globals.css'
import createEmotionCache from '../theme/createEmotionCache'
import theme from '../theme/theme'
import UpdateCheckWrapper from '../components/UpdateCheckWrapper'

axios.defaults.timeout = 30 * 1000

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    },
  },
})

initTrackers()

const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) => {

  return (
    <NoSSR>
      <LocalePathPatcher />
      <UserProvider profileUrl={meEndpoint}>
        <CacheProvider value={emotionCache}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <MobxProvider>
                  <NavigationHistory />
                  <UpdateQueue />
                  <UpdateCheckWrapper>
                    <Component {...pageProps} />
                  </UpdateCheckWrapper>
                  <ActivityTracker />
                </MobxProvider>
              </QueryClientProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </CacheProvider>
      </UserProvider>
    </NoSSR>
  )
}

export default appWithTranslation(App)
